exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-compliance-index-tsx": () => import("./../../../src/pages/compliance/index.tsx" /* webpackChunkName: "component---src-pages-compliance-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-values-index-tsx": () => import("./../../../src/pages/our-values/index.tsx" /* webpackChunkName: "component---src-pages-our-values-index-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */),
  "component---src-pages-team-jesse-tsx": () => import("./../../../src/pages/team/jesse.tsx" /* webpackChunkName: "component---src-pages-team-jesse-tsx" */),
  "component---src-pages-team-oscar-tsx": () => import("./../../../src/pages/team/oscar.tsx" /* webpackChunkName: "component---src-pages-team-oscar-tsx" */),
  "component---src-pages-team-topias-tsx": () => import("./../../../src/pages/team/topias.tsx" /* webpackChunkName: "component---src-pages-team-topias-tsx" */)
}

